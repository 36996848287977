




























































import Vue from 'vue';
import { enums, types } from '@truck-parking/tp-api';
import Timer from '../components/Timer.vue';

export default Vue.extend({
  name: 'ParkingVue',
  components: { Timer },
  data() {
    return {
      loading: true,
      canParkAgain: false,
      parkingAllowedAt: null as string | null,
      startTime: null as string | null,
      cooldownEnabled: true,
      lastParkDuration: null as string | null,
    };
  },
  mounted() {
    this.$api.getStatus().then(({ userStatus }) => {
      this.parkingAllowedAt = userStatus.nextParkingAllowed;
      console.log(this.startTime);
      console.log(userStatus.status);
      console.log(this.parkingAllowedAt);
      if (userStatus.status === enums.UserStatus.Cooldown) {
        this.$api.getParkingEvents().then(({ body: pe }) => {
          this.startTime = pe.parkingEvents[0].endDateTime;
        });
      } else if (userStatus.status === enums.UserStatus.Idle) {
        this.cooldownEnabled = false;
        this.canParkAgain = true;
        this.$api.getParkingEvents().then(({ body: pe }) => {
          this.lastParkDuration = this.getDuration(pe.parkingEvents[0]);
        });
      }

      this.loading = false;
    });
  },
  methods: {
    sendLogOut(): void {
      this.$logOut();
      this.$changeRoute('Home');
    },
    /** return duration in format "1 h 59 min" */
    getDuration(parkingEvent: types.ParkingEvent): string {
      const start = new Date(parkingEvent.startDateTime);
      const end = new Date(parkingEvent.endDateTime);
      const diff = Math.floor((end.getTime() - start.getTime()) / 1000 / 60);
      const hours = Math.floor(diff / 60);
      const mins = diff % 60;
      return `${hours} h ${mins} min`;
    },
  },
});
